//Forms

@import "node_modules/bootstrap/scss/forms";

.form-control {
  color: $black;
  font-weight: 700;
  letter-spacing: .03em;
  padding: 25px 20px;
  background-color: #F5F5F5; /* Light Steel */
  border: 2px solid #F5F5F5; /* Light Steel */
  margin-top: 8px;
  margin-bottom: 8px;
}

.form-control:hover {
  background-color: #fff;
  border: 2px solid #F5F5F5;
  box-shadow: none;
  transition: all 0.25s ease-in-out;
}

.form-control:focus {
  color: $black;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #000;
  transition: opacity 0.25s ease-in-out;
}

:hover::-webkit-input-placeholder {
  color: #616161;
  transition: opacity 0.25s ease-in-out;
}

:focus::-webkit-input-placeholder {
  color: #000;
  opacity: 0.25;
  transition: opacity 0.25s ease-in-out;
  box-shadow: none;
}
