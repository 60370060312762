// Transitions & Animations

@import "node_modules/bootstrap/scss/transitions";

// Animations

.fadein {
  animation: fade-in 1s ease-out forwards;
  opacity: 0;
}

.anima-fadein {
  animation: fade-in 1s ease-out forwards;
  opacity: 0;
  transform: translateY(.5rem);
  padding-right: 2rem;
}

.anima-fadein-delay-800 {
  animation-delay: 800ms;
  opacity: 0;
}

.anima-fadein-delay-1000 {
  animation-delay: 1000ms;
  opacity: 0;
}

.anima-fadein-delay-1200 {
  animation-delay: 1200ms;
  opacity: 0;
}

.anima-fadein-delay-1700 {
  animation-delay: 1700ms;
  opacity: 0;
}

@keyframes fade-in {
  100% {
    opacity: 1;
    transform: none
  }
}
