
// Navbar

$navbar-dark-color:                 rgba(#fff, 1);
$navbar-dark-hover-color:           $gray-600;

@import "node_modules/bootstrap/scss/navbar";

/* Navbar */

.navbar {
  min-height: 43.5px;
}

.navbar-brand-img {
  width: 100px;
}

/* Custom class required for navbars with fixed-* & container classes */
.navbar-fixed-container {
  min-width: 100%;
}

.navbar-nav {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 15px;
  margin-bottom: 35px;
  height: 100vh;
}

div#navbarBasic,
div#navbarBasicLight {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1001;
}

li.nav-item:last-child {
  border: none;
}

.nav-link {
  color: #fff;
  font-weight: 800;
  padding: 10px 25px;
}

.navbar-text {
  font-size: 15px;
  width: 100%;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 8px;
}

.navbar-text-tagline {
  color: #000;
}

button.navbar-toggler { 
  outline: none; /* Removes blue outline */
  border: none; /* Removes border outline */
  padding: 9px;
  padding-right:14px;
  padding-bottom: 14px;
  padding-left:14px;
  z-index: 2000;
  margin-right: -5px;
}

/* Collapse */

.collapsing {
  transition: height .4s ease;
}

/* Icon Toggler */

.icon-bar {
  width: 20px; 
  height: 2px;
  display: block;
  transition: all 0.2s ease-in-out;
  margin-top: 6px
}

.navbar-toggler-dark {
  background-color: #000;
}

.navbar-toggler-light {
  background-color: #fff;
}
 
.navbar-toggler {
  border: none;
  background: transparent;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 23% 40%;
}
 
.navbar-toggler .middle-bar {
  opacity: 0;
}
 
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 21% 40%;
}
 
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}
 
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
 
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

.nav-button {
  font-size: 14px;
  padding: 12px;
  margin-top: 16px;
  margin-left: 0;
  width: 100%;
}

.nav-button:hover {
  color: #fff!important;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .navbar {
    min-height: 80px;
  }

  .navbar-brand {
    margin-left: -3px;
  }

  .navbar-brand-img {
    padding: 0;
    width: 120px;
  }

  .nav-link {
    padding: 8.5px 1rem!important;
  }

  div#navbarBasic,
  div#navbarBasicLight,
  .navbar-fixed-container {
    position: unset;
  }

  .navbar-text {
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbar-nav {
    margin-top: -1px;
    margin-bottom: 0; /* Resets margin on navbar dropdown */
    padding: 0; /* Resets padding on navbar dropdown */
    height: auto; /* Resets vh on small device dropdown */
  }
  
  .nav-button {
    width: auto;
    padding: 10px 24px!important;
    margin-top: 1px;
    margin-left: 16px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .navbar-fixed-container {
    min-width: unset;
  }
}