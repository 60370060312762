// Type
//
// Typography styles are variables located in _variables.scss

@import "node_modules/bootstrap/scss/type";

.font-weight-bold {
  font-weight: 700;
}
.font-weight-heavy {
  font-weight: 800;
}
.font-weight-black {
  font-weight: 900;
}