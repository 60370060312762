// Components
//
// Define common padding and border radius sizes and more.

$border-color:                $white;

$border-radius:               .25rem;
$border-radius-lg:            .25rem;
$border-radius-sm:            .25rem;

$transition-base:             all .2s ease-in-out;

a {
  transition: $transition-base
}

// Hack over text-dark
a.text-dark {
  color: $dark!important;
}

a.text-light {
  color: $light!important;
}

a.text-black {
  color: $black!important;
}

a.text-white {
  color: $white!important;
}

a.text-dark:hover,
a.text-light:hover,
a.text-black:hover,
a.text-white:hover {
  color: $gray-600!important;
}

.line {
  height: 1px;
}